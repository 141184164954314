export const deviceWidths = {
  phoneMaxWidth: 767,
  tabletMaxWidth: 1023,
};

export const remValues = {
  oneRem: getFromWindowOrDefault(),
  halfRem: getFromWindowOrDefault() * .5,
  twoRem: getFromWindowOrDefault() * 2,
};

function getFromWindowOrDefault() {
  if (typeof window === 'undefined') return 16;
  return parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export const productSectionCountVH = 12;
export const productSectionCountGMG = 24;
export const productSectionCountGetAll = 999;
