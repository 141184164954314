<script>
export default {
  computed: {
    blocks() {
      return this.$footer.blocksJson;
    },
  },
};
</script>

<template>
  <footer v-if="blocks" class="site-footer">
    <blocks-repeater :blocks="blocks.Main" />
  </footer>
</template>

<style>
  .site-footer {
    background-color: #EBE5DA ;
    color: var(--color-black);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .site-footer .block-repeater {
    margin: 0;
  }
</style>
