import Vue, { createSSRApp, defineAsyncComponent } from 'vue';
import App from './App.vue';
import consola from 'consola';
import drapejs, { Options, OptionsComponent } from '@drapejs/core';
import { plugin as Litium } from '@motillo/drapejs-litium';
import Worker from 'worker-loader!./web-worker';
import MarkdownIt from 'markdown-it';

import 'regenerator-runtime/runtime';
import './style/index.css';

import {
  emitterKey,
  websiteTextsKey,
  cartKey,
  channelKey,
  headerKey,
  footerKey,
  activeGiftCardKey,
  countrySwitcherKey,
} from './keys';

function createMd() {
  const md = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
    breaks: true,
  });

  md.renderer.rules.table_open = () => '<table class="markdown-table">\n';

  return md;
}
if (process.env.NODE_ENV == 'development') {
  consola.level = 5;
} else {
  consola.level = 3;
}

export default function (
  options: {
    litiumBaseUrl: string;
  },
  workerFactory: () => Worker
) {
  const create = <any>createSSRApp;

  return create(App)
    .use(drapejs, <Options>{
      pageComponents: {
        //TODO: Clean up local dev and remove this template
        Gifts: defineAsyncComponent(() => import('./pages/GiftCategory.vue')),
        Category: defineAsyncComponent(() => import('./pages/GiftCategory.vue')),
        Checkout: defineAsyncComponent(() => import('./pages/Checkout.vue')),
        ContentPage: defineAsyncComponent(() => import('./pages/ContentPage.vue')),
        OrderTrackingPage: defineAsyncComponent(() => import('./pages/OrderTrackingPage.vue')),
        GiftcardFavoritesPage: defineAsyncComponent(() => import('./pages/GiftcardFavoritesPage.vue')),
        Product: defineAsyncComponent(() => import('./pages/Product.vue')),
        GetMyGiftReceipt: defineAsyncComponent(() => import('./pages/Receipt.vue')),
        Sso: defineAsyncComponent(() => import('./pages/Sso.vue')),
      },
      workerFactory,
    })
    .use(Litium as Vue.Plugin, {
      baseUrl: options.litiumBaseUrl,
      blockComponents: {
        HTMLBlock: defineAsyncComponent(() => import('./blocks/Html.vue')),
        GetMyGiftFooter: defineAsyncComponent(() => import('./blocks/GetMyGiftFooter.vue')),
        GetMyGiftBlock: defineAsyncComponent(() => import('./blocks/GetMyGiftBlock.vue')),
        MultiImageTextBlock: defineAsyncComponent(() => import('./blocks/MultiImageTextBlock.vue')),
        ProductListBlockWithCondition: defineAsyncComponent(() => import('./blocks/ProductListBlockWithCondition.vue')),
        TextWithTitle: defineAsyncComponent(() => import('../../shared/blocks/TextWithTitle')),
        HeroBlock: defineAsyncComponent(() => import('../../shared/blocks/Hero')),
        CenteredText: defineAsyncComponent(() => import('../../shared/blocks/CenteredText')),
        MediaBlock: defineAsyncComponent(() => import('../../shared/blocks/Media')),
        TitleParagraph: defineAsyncComponent(() => import('../../shared/blocks/TitleParagraph')),
        TextWithMedia: defineAsyncComponent(() => import('../../shared/blocks/TextWithMedia')),
        LinkBoxes: defineAsyncComponent(() => import('../../shared/blocks/LinkBoxes')),
        RelatedLinks: defineAsyncComponent(() => import('../../shared/blocks/RelatedLinks')),
        FeaturedPosts: defineAsyncComponent(() => import('../../shared/blocks/FeaturedPosts')),
        InfoCards: defineAsyncComponent(() => import('../../shared/blocks/InfoCards')),
        ProductBlock: defineAsyncComponent(() => import('../../shared/blocks/Product')),
        SplitImageIntro: defineAsyncComponent(() => import('../../shared/blocks/SplitImageIntro')),
        Logotypes: defineAsyncComponent(() => import('../../shared/blocks/Logotypes')),
        TextWithProduct: defineAsyncComponent(() => import('../../shared/blocks/TextWithProduct')),
        TextWith2Images: defineAsyncComponent(() => import('../../shared/blocks/TextWith2Images')),
        LinkBoxesWithText: defineAsyncComponent(() => import('../../shared/blocks/LinkBoxesWithText')),
        TextAndImage: defineAsyncComponent(() => import('./blocks/TextAndImage.vue')),
      },
    })
    .mixin({
      extends: OptionsComponent,
      inject: {
        $channelReactive: {
          from: channelKey,
          default: {},
        },
        $cartReactive: {
          from: cartKey,
          default: {},
        },
        $websiteTextsReactive: {
          from: websiteTextsKey,
          default: {},
        },
        $countrySwitcherReactive: {
          from: countrySwitcherKey,
          default: {},
        },
        $emitter: {
          from: emitterKey,
          default: {},
        },
        $headerReactive: {
          from: headerKey,
          default: {},
        },
        $footerReactive: {
          from: footerKey,
          default: {},
        },
        $activeGiftCardReactive: {
          from: activeGiftCardKey,
          default: {},
        },
      } as any,
      computed: {
        $channel() {
          return this.$channelReactive || {};
        },
        $cart() {
          return this.$cartReactive || {};
        },
        $globalTexts() {
          return this.$channelReactive?.website?.texts || {};
        },
        $globalFields() {
          return this.$channelReactive?.website?.fields || {};
        },
        $mitt() {
          return this.$emitter;
        },
        $header() {
          return this.$headerReactive || {};
        },
        $footer() {
          return this.$footerReactive || {};
        },
        $activeGiftCard() {
          return this.$activeGiftCardReactive || {};
        },
        $countrySwitcher() {
          return this.$countrySwitcherReactive || {};
        },
      } as any,
      methods: {
        $renderMarkdown(text: string) {
          if (!text) {
            return '';
          }

          return createMd().render(text);
        },
        $renderMarkdownInline(text: string) {
          if (!text) {
            return '';
          }

          return createMd().renderInline(text);
        },
        $toBlock(template: string) {
          if (!template) return '';

          return `block-${template.toLowerCase()}`;
        },
      } as any,
    });
}
