<script setup lang="ts">
import { ref, computed } from "vue";

import useWebsiteTexts from "@/composables/useWebsiteTexts";
import useContext from "@/composables/useContext";
import { deviceWidths } from "../Constants";
const highlightProductImgWidth = ref(window.innerWidth).value < deviceWidths.phoneMaxWidth ? ref(window.innerWidth).value : ref(window.innerWidth).value / 2;
const regularProductImgWidth = highlightProductImgWidth / 2;

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  categoryId: {
    type: String,
    default: null,
  },
  productSections: {
    type: Array,
    default: [],
  },
  tag: {
    type: Object,
    default: null,
  },
  isFavoriteProduct: {
    type: Boolean,
    default: false,
  },
  showFavoriteProducts: {
    type: Boolean,
    default: false,
  },
  toggleFavoriteProduct: {
    type: Function
  }
});

const { toLitiumMediaUrl } = useContext();
const { websiteText } = useWebsiteTexts();

const hover = ref(false);

const productUrl = computed(() => {
  if (!props.product?.url) return "";

  if (props.tag?.key && props.tag.value) {
    const sectionQueryParamValue = `${props.tag.key || ""}::${props.tag.value || ""}`;
    const sectionQueryParam = `?section=${encodeURIComponent(sectionQueryParamValue)}`;

    return `${props.product.url}${sectionQueryParam}`;
  }
  return props.product.url;
});

const productImageUrl = computed(() => {
  const width = props.product.highlighted
    ? highlightProductImgWidth
    : regularProductImgWidth;
  const tmp = toLitiumMediaUrl((hover.value ? props.product?.secondaryImage : props.product?.primaryImage ) || props.product?.primaryImage || props.product?.imageId || props.product?.imageIds[0], {
    maxWidth: width,
  });
  return tmp;
});

const productName = computed(() => {
  return props.product?.productName || props.product?.name || "";
});

const brandName = computed(() => {
  return props.product?.brandName || "";
});

const comingSoon = computed(() => {
  return props.product?.comingSoon || false;
});

</script>

<template>
  <router-link :to="productUrl" class="product-card">
    <div class="product-card__product-media">
      <div class="product-card__image-container">
        <!-- <div class="product-card__product-label-container" v-if="true"> //This is for the label above product cards, no functionality yet so simply hidden for now
          <div class="product-card__product-label">
            {{ websiteText("common_comingsoon").value }}
          </div>
        </div> -->
        <div class="product-card__product-incoming-container" v-if="comingSoon">
          <div class="product-card__product-incoming">
            {{ websiteText("common_comingsoon").value }}
          </div>
        </div>
        <div class="product-card__image-overlay"></div>
        <div class="product-card__image-spacer"></div>
        <img
          @mouseover="hover = true"
          @mouseleave="hover = false"
          @touchstart="hover = true"
          @touchend="hover = false"
          v-if="productImageUrl"
          :src="productImageUrl"
          class="product-card__image"
          :class="{ 'product-card__image-incoming': comingSoon }"
        />
      </div>
    </div>
    <!-- <div class="product-card__variant-selector"> //This is for the variant selector circles, no functionality yet so simply hidden for now
      <div class="product-card__variant-selector-option" style="background-color: rgba(84, 144, 164, 0.5);">
      </div>
      <div class="product-card__variant-selector-option" style="background-color: grey">
      </div>
      <div class="product-card__variant-selector-option" style="background-color: black">
      </div>
      <div class="product-card__variant-selector-option" style="background-color: white">
      </div>
    </div> -->

    <div class="product-card__bottom">
      <div class="product-card__info">
        <div class="product-card__brand-name">
          {{ brandName }}
        </div>
        <div class="product-card__name">
          {{ productName }}
        </div>
      </div>
      <div 
      v-if="showFavoriteProducts"
        class="product-card__favorite"
        @click="(event) => toggleFavoriteProduct(event, product)"
      >
        <img
          v-if="!isFavoriteProduct"
          src="../icons/heart.svg"
        />
        <img
          v-if="isFavoriteProduct"
          src="../icons/heart_filled.svg"
        />
      </div>
    </div>
  </router-link>
</template>

<style>
.product-card {
  background-color: white;
  position: relative;
  cursor: pointer;
  padding: 5.4px;
  text-decoration: none;
}

.product-card__product-media {
  background-color: var(--color-white);
}

.product-card__image-container {
  position: relative;
}

.product-card__product-label-container{
  position: absolute;
  z-index: 100;
  background: black;
  top: 1rem;
  left: 1rem;
  color: lightgrey;
  padding: 0.5rem;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 200;
}

.product-card__product-incoming-container {
  z-index: 20;
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.product-card__product-incoming {
  font-family: var(--font-mulish-bold);
  font-size: 12px;
  line-height: 18px;
  color: var(--color-white);
  padding: 8px 38px;
  background: rgba(84, 144, 164, 0.5);
  backdrop-filter: blur(4px);
  text-align: center;
  font-weight: 700;
}

.product-card__variant-selector{
  margin-left: 1rem;
  display: flex;
  height: 10px;
}

.product-card__variant-selector-option{
  display:block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 1px solid black;
  margin-right: 4px;
}

.product-card__bottom{
  display: flex;
  justify-content: space-between;
}

.product-card__favorite{
  width: 35px;
  margin: 1rem;
  justify-content: center;
  align-self: center;
}

@media (--phone) {
  .product-card__product-incoming {
    font-size: 10px;
    line-height: 16px;
    padding: 4px 19px;
  }
}

/* Fix IE11 opacity bug */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .product-card__image-overlay {
    opacity: 0;
  }
}

.product-card__image {
  padding: 12px 16px 16px;
  width: 100%;
  aspect-ratio: 1/1.3;
  object-fit: cover;
}

.product-card__info {
  text-align: center;
  background-color: var(--color-white);
  padding: 12px 16px 16px; /* when making space for the favorite heart, change to 12px 0 12px 1rem;
  /* max-width: calc(100% - 35px - 2rem); */ /* This is to leave space for the favorites heart, hidden for now */
}

.product-card__name {
  font-size: 15px;
  font-weight: 700;
  font-family: var(--font-mulish-semi-bold);
  color: var(--color-black);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  line-height: 1.3rem;

  @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
}

.product-card__brand-name {
  font-size: 12px;
  color: var(--color-black);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 15px;
  margin-bottom: 6px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: left;
}

@media (--phone) {
  .product-card__name {
    font-size: 12px;
  }
}
</style>
