<script>
  import ActiveGiftCard from '@/components/header/ActiveGiftCart.vue';
  import Logout from '@/components/header/Logout.vue';

  export default {
    components: {
      ActiveGiftCard,
      Logout,
    },
    props: {
      logoUrl: {
        type: String,
        required: true,
      },
    },
    data: () => ({
      showCountrySwitcher: false,
    }),
    computed: {
      hasAnyGiftCards() {
        console.warn(this.$activeGiftCard);
        return this.$activeGiftCard?.giftCards?.length > 0;
      },
      rootPathRedirectUrl() {
        return this.$channel.rootPathRedirectUrl || '';
      },
    },
  };
</script>

<template>
  <div class="desktop-header">
    <div class="desktop-header__content">
      <div class="desktop-header__logo">
        <router-link
          :to="rootPathRedirectUrl"
          class="desktop-header__logo-link">
          <img
            :src="logoUrl"
            alt="logo" />
        </router-link>
      </div>
      <div
      class="desktop-header__other-actions"
      v-if="hasAnyGiftCards">
        <img
          src="../../static/icons/search.svg"
          class="desktop-header__search"
          @click="$mitt.emit('Search:Open')" />
          <active-gift-card />
      </div>
    </div>
  </div>
</template>

<style>
  .desktop-header {
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 1rem 1rem 0;
    max-width: calc(var(--site-max-width) + 2rem);
    padding-bottom: 1rem;
  }

  .desktop-header__content {
    display: flex;
    justify-content: space-between;
    height: 42px;
    position: relative;
  }

  .desktop-header__logo {
    width: 100%;
    height: 29px;
    display: flex;
    justify-content: space-around;
    align-self: center;
  }

  .desktop-header__logo img {
    height: 29px;
  }
  
  .desktop-header__other-actions {
    position: absolute;
    right: 0;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: 25px;
  }

  .desktop-header__search {
    width: 21px;
    cursor: pointer;
  }
</style>
